import { PlusIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import Button from '../Button';
import CreateNewModal from '../NewModals/create-new';

export default function EmptyStateSection({
  icon,
  title,
  text,
  demo = null,
  click = null,
  buttonIcon = null,
  buttonText = 'Create new',
  type = 'create',
  noCTA = false,
  STRIPE_PK,
}) {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <CreateNewModal open={isOpen} setOpen={setOpen} STRIPE_PK={STRIPE_PK} />
      <div
        className={
          'col-span-3 flex w-full flex-col items-center justify-center space-y-4 rounded-xl  border-dashed bg-gray-200 py-16 px-8 text-center text-base font-normal text-gray-700 sm:text-xl'
        }
      >
        <div className="flex flex-col items-center justify-center text-center">
          {icon}
          <h3 className="mt-2 font-sans font-semibold tracking-tight  text-gray-900">
            {title}
          </h3>
          <p className="mt-1 text-sm font-medium text-gray-500">{text}</p>
          <div className={`${noCTA ? 'hidden' : 'flex'} mt-6`}>
            <Button
              size={'small'}
              click={() => (type === 'create' ? setOpen(true) : click)}
            >
              {buttonText}
            </Button>
          </div>
          {/*                 {type === "create" && noCTA === false ? 
                <div className={" w-full max-w-2xl rounded-lg mt-12 p-8 bg-gradient-to-br from-[#C242FF] to-[#FF5D40]"}>

                    <img src={"create-demo.gif"} className={"rounded-md"}/>

                </div>
                : null} */}
        </div>
      </div>
    </>
  );
}
