export default function MediumLogo({ className }) {
  return (
    <svg
      className={`${className} fill-current`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.5084 12.4891C13.5084 16.176 10.5399 19.165 6.87797 19.165C3.21606 19.165 0.247803 16.1769 0.247803 12.4891C0.247803 8.80132 3.21628 5.81348 6.87797 5.81348C10.5397 5.81348 13.5084 8.80222 13.5084 12.4891Z" />
      <path d="M20.7818 12.489C20.7818 15.9597 19.2974 18.7731 17.4666 18.7731C15.6357 18.7731 14.1514 15.9588 14.1514 12.489C14.1514 9.01909 15.6357 6.20483 17.4666 6.20483C19.2974 6.20483 20.7818 9.01909 20.7818 12.489Z" />
      <path d="M23.7568 12.4887C23.7568 15.5984 23.2347 18.119 22.5908 18.119C21.9469 18.119 21.4248 15.5975 21.4248 12.4887C21.4248 9.37997 21.9469 6.8584 22.591 6.8584C23.2352 6.8584 23.7568 9.3793 23.7568 12.4887Z" />
    </svg>
  );
}
