export default function EssayLoadingState() {
  return (
    <div
      className={
        'flex h-96 flex-col items-start justify-between rounded-2xl bg-white p-4'
      }
    >
      <div className={'h-4 w-24 animate-pulse rounded-md bg-gray-100'} />
      <div className={'flex w-full flex-col items-start justify-start'}>
        <div
          className={'mb-2 h-8 w-full animate-pulse rounded-md bg-gray-100'}
        />
        <div
          className={'mb-1 h-4 w-3/4 animate-pulse rounded-md bg-gray-100'}
        />
        <div className={'h-4 w-1/4 animate-pulse rounded-md bg-gray-100'} />
      </div>
      <div className={'flex flex-row items-center justify-start space-x-2'}>
        <div className={'h-4 w-4 animate-pulse rounded-full bg-gray-100'} />
        <div className={'h-4 w-16 animate-pulse rounded-md bg-gray-100'} />
      </div>
    </div>
  );
}
